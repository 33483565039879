import PrivacyStatementModal from '@/pages/PrivacyStatement'
import Disclaimer from '@/pages/Disclaimer'
import Subscribe from '@/pages/Subscribe'
import RecentNewsLetter from '@/pages/RecentNewsLetter'
import Contact from '@/pages/Contact'
import Newsletter from '@/components/modals/NewsletterModal'
import ProductModal from '@/components/modals/ProductModal'
import ArticleModal from '@/components/modals/ArticleModal'

const PAGE_STATIC = 'static'
const PAGE_DYNAMIC = 'dynamic'

export default {
  article: {
    contentType: 'articles',
    aliases: [
      'article',
      'artikel',
    ],
    pageType: PAGE_DYNAMIC,
    component: ArticleModal,
  },
  contact: {
    contentType: 'contact',
    aliases: [],
    pageType: PAGE_STATIC,
    component: Contact,
  },
  disclaimer: {
    contentType: 'disclaimer',
    aliases: [],
    pageType: PAGE_STATIC,
    component: Disclaimer,
  },
  newsletter: {
    contentType: 'newsletters',
    aliases: [
      'newsletter',
      'nieuwsbrief',
    ],
    pageType: PAGE_DYNAMIC,
    component: Newsletter
  },
  privacyStatement: {
    contentType: 'privacy-statement',
    aliases: [],
    pageType: PAGE_STATIC,
    component: PrivacyStatementModal
  },
  product: {
    contentType: 'products',
    aliases: [
      'product',
      'woning',
      'object',
    ],
    pageType: PAGE_DYNAMIC,
    component: ProductModal,
  },
  recentNewsletter: {
    contentType: 'recent-newsletter',
    aliases: [],
    pageType: PAGE_STATIC,
    component: RecentNewsLetter
  },
  subscribe: {
    contentType: 'newsletter-subscribe',
    aliases: [],
    pageType: PAGE_STATIC,
    component: Subscribe,
  }
}

export { PAGE_DYNAMIC, PAGE_STATIC }
